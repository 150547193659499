<template>
    <div class="sidebar-left">
        <div class="sidebar">
            <!-- Logged In User Profile Sidebar -->
            <user-profile-sidebar
                :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"
                :profile-user-data="profileUserData"
                @close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"
            />

            <!-- Sidebar Content -->
            <div class="sidebar-content" :class="{ show: mqShallShowLeftSidebar }">
                <!-- Sidebar close icon -->
                <span class="sidebar-close-icon">
                    <feather-icon icon="XIcon" size="16" @click="$emit('update:mq-shall-show-left-sidebar', false)" />
                </span>

                <!-- Header -->
                <div class="chat-fixed-search">
                    <div class="d-flex align-items-center w-100">
                        <div class="sidebar-profile-toggle">
                            <b-avatar
                                size="42"
                                class="cursor-pointer badge-minimal avatar-border-2"
                                :src="profileUserMinimalData.avatar"
                                badge
                                badge-variant="success"
                                @click.native="$emit('show-user-profile')"
                            />
                            <!--                variant="transparent"-->
                        </div>
                        <!-- Search -->
                        <b-input-group class="input-group-merge ml-1 w-100 round">
                            <b-input-group-prepend is-text>
                                <feather-icon icon="SearchIcon" class="text-muted" />
                            </b-input-group-prepend>
                            <b-form-input v-model="searchQuery" placeholder="Search..." />
                        </b-input-group>
                    </div>
                </div>

                <!-- ScrollArea: Chat & Contacts -->
                <vue-perfect-scrollbar
                    :settings="perfectScrollbarSettings"
                    class="chat-user-list-wrapper list-group scroll-area"
                >
                    <!-- Chats Title -->
                    <h4 class="chat-list-title">Chats</h4>
                    <div
                        v-if="!filteredChatsContacts.length > 0 && Array.isArray(filteredChatsContacts)"
                        class="h-100 d-flex justify-content-center align-items-center"
                    >
                        <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
                    </div>
                    <!-- Chats -->
                    <ul v-else class="chat-users-list chat-list media-list">
                        <chat-contact
                            v-for="contact in filteredChatsContacts"
                            :key="contact.id"
                            :user="contact"
                            tag="li"
                            :class="{ active: activeChatContactId === contact.id }"
                            is-chat-contact
                            @click="$emit('open-chat', contact.id)"
                        />
                    </ul>

                    <!-- Contacts Title -->
                    <!--          <h4 class="chat-list-title">-->
                    <!--            Contacts-->
                    <!--          </h4>-->

                    <!--          &lt;!&ndash; Contacts &ndash;&gt;-->
                    <!--          <ul class="chat-users-list contact-list media-list">-->
                    <!--            <chat-contact-->
                    <!--              v-for="contact in filteredContacts"-->
                    <!--              :key="contact.id"-->
                    <!--              :user="contact"-->
                    <!--              tag="li"-->
                    <!--              @click="$emit('open-chat', contact.id)"-->
                    <!--            />-->
                    <!--          </ul>-->
                </vue-perfect-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
import { BAvatar, BFormInput, BInputGroup, BInputGroupPrepend, BSpinner } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { computed, ref } from "@vue/composition-api";
import ChatContact from "./ChatContact.vue";
import UserProfileSidebar from "./UserProfileSidebar.vue";

export default {
    components: {
        // BSV
        BAvatar,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BSpinner,

        // 3rd party
        VuePerfectScrollbar,

        // SFC
        ChatContact,
        UserProfileSidebar,
    },
    props: {
        // chatsContacts: {
        //     type: Array,
        //     required: true,
        // },
        // contacts: {
        //     type: Array,
        //     required: true,
        // },
        chats: {
            type: Array,
            required: true,
        },
        shallShowUserProfileSidebar: {
            type: Boolean,
            required: false,
        },
        profileUserData: {
            type: Object,
            required: false,
            default: () => ({
                about: "United Luxury",
                settings: {
                    isTwoStepAuthVerificationEnabled: false,
                    isEmailVerified: false,
                },
                status: "online",
                role: "Shop",
                avatar: "",
                fullName: "United Luxury Shop",
            }),
        },
        profileUserMinimalData: {
            type: Object,
            required: false,
            default: () => ({
                id: "0",
                name: "United Luxury",
                avatar: "",
                status: "online",
            }),
        },
        activeChatContactId: {
            type: Number,
            default: null,
        },
        mqShallShowLeftSidebar: {
            type: Boolean,
            required: false,
        },
    },
    setup(props) {
        const perfectScrollbarSettings = {
            maxScrollbarLength: 150,
        };

        const resolveChatContact = userId => props.chats.find(contact => contact.id === userId);

        // Search Query
        const searchQuery = ref("");

        const searchFilterFunction = contact => contact.number.includes(searchQuery.value);
        const filteredChatsContacts = computed(() => props.chats.filter(searchFilterFunction));

        return {
            // Search Query
            searchQuery,
            filteredChatsContacts,
            // filteredContacts,

            // UI
            resolveChatContact,
            perfectScrollbarSettings,
        };
    },
};
</script>
