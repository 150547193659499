import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getChats(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/sms", {
                        params: queryParams,
                    })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        getChatDetails(ctx, { userId }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/sms/${userId}`)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        sendSms(ctx, { id, message }) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/sms", {
                        messageBody: message,
                        contactId: id,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        readSms(ctx, { userId }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/sms/read/${userId}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
    },
};
