<template>
    <component :is="tag" v-on="$listeners">
        <b-avatar size="42" :badge="resolveIsRead(user.isRead)" class="badge-minimal" />
        <!--      variant="transparent"-->
        <!--      :badge-variant="resolveAvatarBadgeVariant(user.status)"-->
        <!--      :src="user.avatar"-->
        <div class="chat-info flex-grow-1">
            <h5 class="mb-0">
                {{ user.number }}
            </h5>
            <p class="card-text text-truncate">
                {{ isChatContact ? user.lastMessage : "*Last message not found*" }}
            </p>
        </div>
        <div v-if="isChatContact" class="chat-meta text-nowrap">
            <small class="float-right mb-25 chat-time">{{
                formatDateToMonthShort(user.lastMessageDate, { hour: "numeric", minute: "numeric" })
            }}</small>
            <!-- <b-badge
        v-if="user.chat.unseenMsgs"
        pill
        variant="primary"
        class="float-right"
      >
        {{ user.chat.unseenMsgs }}
      </b-badge> -->
        </div>
    </component>
</template>

<script>
import { BAvatar, BBadge } from "bootstrap-vue";
import { formatDateToMonthShort } from "@core/utils/filter";
// import useChat from "./useChat";

export default {
    components: {
        BAvatar,
        BBadge,
    },
    props: {
        tag: {
            type: String,
            default: "div",
        },
        user: {
            type: Object,
            required: true,
        },
        isChatContact: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const resolveIsRead = isRead => {
            if (isRead) {
                return undefined;
            } else {
                return "";
            }
        };

        return {
            formatDateToMonthShort,
            resolveIsRead,
        };
    },
};
</script>

<style></style>
