<template>
    <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
    <div style="height: inherit" class="chat-application">
        <div
            class="body-content-overlay"
            :class="{
                show: shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar,
            }"
            @click="mqShallShowLeftSidebar = shallShowActiveChatContactSidebar = shallShowUserProfileSidebar = false"
        />
        <!-- Main Area -->
        <section class="chat-app-window">
            <!--        Initial loading-->
            <!-- Start Chat Logo -->

            <div v-if="!activeChat.contact" class="start-chat-area">
                <div class="mb-1 start-chat-icon">
                    <feather-icon icon="MessageSquareIcon" size="56" />
                </div>
                <h4 class="sidebar-toggle start-chat-text" @click="startConversation">Start Conversation</h4>
            </div>

            <!-- Chat Content -->
            <div v-else class="active-chat">
                <!-- Chat Navbar -->
                <div class="chat-navbar">
                    <header class="chat-header">
                        <!-- Avatar & Name -->
                        <div class="d-flex align-items-center">
                            <!-- Toggle Icon -->
                            <div class="sidebar-toggle d-block d-lg-none mr-1">
                                <feather-icon
                                    icon="MenuIcon"
                                    class="cursor-pointer"
                                    size="21"
                                    @click="mqShallShowLeftSidebar = true"
                                />
                            </div>

                            <b-avatar
                                size="36"
                                class="mr-1 cursor-pointer badge-minimal"
                                badge
                                @click.native="shallShowActiveChatContactSidebar = true"
                            />
                            <!--                :badge-variant="resolveAvatarBadgeVariant(activeChat.contact.status)"-->
                            <!--                :src="activeChat.contact.avatar"-->
                            <h6 class="mb-0">
                                {{ activeChat.contact.number }}
                            </h6>
                        </div>

                        <!-- Contact Actions -->
                        <div class="d-flex align-items-center">
                            <!--              <feather-icon-->
                            <!--                icon="PhoneCallIcon"-->
                            <!--                size="17"-->
                            <!--                class="cursor-pointer d-sm-block d-none mr-1"-->
                            <!--              />-->
                            <!--              <feather-icon-->
                            <!--                icon="VideoIcon"-->
                            <!--                size="17"-->
                            <!--                class="cursor-pointer d-sm-block d-none mr-1"-->
                            <!--              />-->
                            <!--              <feather-icon-->
                            <!--                icon="SearchIcon"-->
                            <!--                size="17"-->
                            <!--                class="cursor-pointer d-sm-block d-none mr-50"-->
                            <!--              />-->
                            <!--              <div class="dropdown">-->
                            <!--                <b-dropdown-->
                            <!--                  variant="link"-->
                            <!--                  no-caret-->
                            <!--                  toggle-class="p-0"-->
                            <!--                  right-->
                            <!--                >-->
                            <!--                  <template #button-content>-->
                            <!--                    <feather-icon-->
                            <!--                      icon="MoreVerticalIcon"-->
                            <!--                      size="17"-->
                            <!--                      class="align-middle text-body"-->
                            <!--                    />-->
                            <!--                  </template>-->
                            <!--                  <b-dropdown-item>-->
                            <!--                    View Contact-->
                            <!--                  </b-dropdown-item>-->
                            <!--                  <b-dropdown-item>-->
                            <!--                    Mute Notifications-->
                            <!--                  </b-dropdown-item>-->
                            <!--                  <b-dropdown-item>-->
                            <!--                    Block Contact-->
                            <!--                  </b-dropdown-item>-->
                            <!--                  <b-dropdown-item>-->
                            <!--                    Clear Chat-->
                            <!--                  </b-dropdown-item>-->
                            <!--                  <b-dropdown-item>-->
                            <!--                    Report-->
                            <!--                  </b-dropdown-item>-->
                            <!--                </b-dropdown>-->
                            <!--              </div>-->
                        </div>
                    </header>
                </div>

                <!-- User Chat Area -->
                <vue-perfect-scrollbar
                    ref="refChatLogPS"
                    :settings="perfectScrollbarSettings"
                    class="user-chats scroll-area"
                >
                    <chat-log :chat-data="activeChat" :profile-user-avatar="profileUserDataMinimal.avatar" />
                </vue-perfect-scrollbar>

                <!-- Message Input -->
                <b-form class="chat-app-form" @submit.prevent="sendMessage">
                    <b-input-group class="input-group-merge form-send-message mr-1">
                        <b-form-input v-model="chatInputMessage" placeholder="Enter your message" />
                    </b-input-group>
                    <b-button variant="primary" type="submit"> Send </b-button>
                </b-form>
            </div>
        </section>

        <!-- Active Chat Contact Details Sidebar -->
        <chat-active-chat-content-details-sidedbar
            :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
            :contact="activeChat.contact || {}"
        />

        <!-- Sidebar -->
        <portal to="content-renderer-sidebar-left">
            <chat-left-sidebar
                :chats="chats"
                :active-chat-contact-id="activeChat.contact ? activeChat.contact.id : null"
                :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
                :profile-user-data="profileUserData"
                :profile-user-minimal-data="profileUserDataMinimal"
                :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
                @show-user-profile="showUserProfileSidebar"
                @open-chat="openChatOfContact"
            />
            <!--        :contacts="contacts"-->
            <!--        :chats-contacts="chatsContacts"-->
        </portal>
    </div>
</template>

<script>
import store from "@/store";
import { nextTick, onUnmounted, ref } from "@vue/composition-api";
import { BAvatar, BButton, BForm, BFormInput, BInputGroup } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from "../../../../themeConfig";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import ChatLeftSidebar from "./ChatLeftSidebar.vue";
import chatStoreModule from "./chatStoreModule";
import ChatActiveChatContentDetailsSidedbar from "./ChatActiveChatContentDetailsSidedbar.vue";
import ChatLog from "./ChatLog.vue";
import useChat from "./useChat";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    components: {
        // BSV
        BAvatar,
        // BDropdown,
        // BDropdownItem,
        BForm,
        BInputGroup,
        BFormInput,
        BButton,
        // 3rd Party
        VuePerfectScrollbar,

        // SFC
        ChatLeftSidebar,
        ChatActiveChatContentDetailsSidedbar,
        ChatLog,
    },
    setup() {
        const CHAT_APP_STORE_MODULE_NAME = "sms";
        const toast = useToast();

        // Register module
        if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) {
            store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule);
        }

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(CHAT_APP_STORE_MODULE_NAME);
            }
        });
        const { resolveAvatarBadgeVariant } = useChat();
        const chatsLoading = ref(true);
        // Scroll to Bottom ChatLog
        const refChatLogPS = ref(null);
        const scrollToBottomInChatLog = () => {
            const scrollEl = refChatLogPS.value.$el || refChatLogPS.value;
            scrollEl.scrollTop = scrollEl.scrollHeight;
        };

        // ------------------------------------------------
        // Chats & Contacts
        // ------------------------------------------------
        const chats = ref([]);
        // const contacts = ref([]);

        const fetchChats = () => {
            store
                .dispatch("sms/getChats")
                .then(response => {
                    chats.value = response.contacts;
                    chatsLoading.value = false;
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error getting chats",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                });
        };
        fetchChats();

        // ------------------------------------------------
        // Single Chat
        // ------------------------------------------------
        const activeChat = ref({});
        const chatInputMessage = ref("");

        const readSms = userId => {
            store
                .dispatch("sms/readSms", {
                    userId,
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error reading sms",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                });
        };
        const openChatOfContact = userId => {
            // Find The Chat of the Contact
            let chatOfContact = chats.value.find(chat => chat.id === userId);
            // Set isRead to true after opening chat
            chatOfContact.isRead = true;
            // Reset send message input value
            chatInputMessage.value = "";
            nextTick(() => {
                store
                    .dispatch("sms/getChatDetails", {
                        userId,
                    })
                    .then(response => {
                        activeChat.value = response;
                        // Scroll to bottom
                        nextTick(() => {
                            scrollToBottomInChatLog();
                        });

                        return activeChat;
                    })
                    .catch(() => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: "An error has occured while opening chat",
                                icon: "AlertTriangleIcon",
                                variant: "danger",
                            },
                        });
                    });
            });

            readSms(userId);
            // if SM device =>  Close Chat & Contacts left sidebar
            // eslint-disable-next-line no-use-before-define
            mqShallShowLeftSidebar.value = false;
        };

        // TODO SEND MESSAGE FUNCTIONALITY
        const sendMessage = () => {
            if (!chatInputMessage.value) {
                return;
            }
            // const payload = {
            //     contactId: activeChat.value.contact.id,
            //     // eslint-disable-next-line no-use-before-define
            //     // senderId: profileUserDataMinimal.value.id,
            //     message: chatInputMessage.value,
            // };
            store
                .dispatch("sms/sendSms", {
                    id: activeChat.value.contact.id,
                    message: chatInputMessage.value,
                })
                .then(response => {
                    const newMessageData = response.data;
                    // Push message to data array
                    activeChat.value.messages.push(newMessageData);
                    // Reset send message input value
                    chatInputMessage.value = "";

                    // Set Last Message for active contact
                    const contact = chats.value.find(c => c.id === activeChat.value.contact.id);
                    contact.lastMessage = newMessageData.body;

                    // Scroll to bottom
                    nextTick(() => {
                        scrollToBottomInChatLog();
                    });
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Message sent",
                            icon: "CheckCircleIcon",
                            variant: "success",
                        },
                    });
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error sending message",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                });
        };

        const perfectScrollbarSettings = {
            maxScrollbarLength: 150,
        };

        // User Profile Sidebar
        // ? Will contain all details of profile user (e.g. settings, about etc.)
        // All preset, will be modified LATER
        const profileUserData = ref({
            about: "United Luxury",
            settings: {
                isTwoStepAuthVerificationEnabled: false,
                isEmailVerified: false,
            },
            status: "online",
            role: "Shop",
            avatar: "",
            fullName: "United Luxury Shop",
        });
        // ? Will contain id, name and avatar & status
        const profileUserDataMinimal = ref({
            id: "0",
            name: "United Luxury",
            avatar: "",
            status: "online",
        });

        const shallShowUserProfileSidebar = ref(false);
        const showUserProfileSidebar = () => {
            // store.dispatch("app-chat/getProfileUser")
            //     .then(response => {
            //         profileUserData.value = response.data;
            shallShowUserProfileSidebar.value = true;
            // });
        };

        // Active Chat Contact Details
        const shallShowActiveChatContactSidebar = ref(false);

        // UI + SM Devices
        // Left Sidebar Responsiveness
        const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
        const startConversation = () => {
            if (store.state.app.windowWidth < $themeBreakpoints.lg) {
                mqShallShowLeftSidebar.value = true;
            }
        };

        return {
            // Filters
            // formatDate,

            // useChat
            resolveAvatarBadgeVariant,

            // Chat & Contacts

            chats,
            // chatsContacts,
            // contacts,

            // Single Chat
            refChatLogPS,
            activeChat,
            chatsLoading,
            chatInputMessage,
            openChatOfContact,
            sendMessage,
            readSms,

            // Profile User Minimal Data
            profileUserDataMinimal,

            // User Profile Sidebar
            profileUserData,
            shallShowUserProfileSidebar,
            showUserProfileSidebar,

            // Active Chat Contact Details
            shallShowActiveChatContactSidebar,

            // UI
            perfectScrollbarSettings,

            // UI + SM Devices
            startConversation,
            mqShallShowLeftSidebar,
        };
    },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
